// is touch
function isTouchDevice() {
	return (("ontouchstart" in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
}

if (isTouchDevice()) {
	$("body").removeClass("des");
	$("body").addClass("mob");
} else {
	$("body").removeClass("mob");
	$("body").addClass("des");
}

const header = document.querySelector(".header");

const appHeights = () => {
	const doc = document.documentElement
	let headerHeight = $('.header').outerHeight();
	let windowHeight = $(window).height();
	doc.style.setProperty('--app-height', windowHeight + "px");
	doc.style.setProperty('--header-height', headerHeight + "px");
}
appHeights();


// Burger
$(".main-nav__toggler").on("click", function () {
	$(this).toggleClass("active");
	$(".main-nav__list").toggleClass("active");
});


// Resize
$(window).on("resize", function () {
	// is touch
	isTouchDevice();

	// app heights
	appHeights();
});


// Scroll
$(window).on("scroll", function () {
	// app heights
	appHeights();
})


// Document click
$(document).on("click", function (e) {
	// Header nav
	if ($(".main-nav__toggler").hasClass("active") && !window.matchMedia("(min-width: 1024px)").matches) {
		var callbackPopup = $(".main-nav__toggler, .main-nav__list");
		if (!callbackPopup.is(e.target) && callbackPopup.has(e.target).length === 0) {
			$(".main-nav__toggler").removeClass("active");
			$(".main-nav__list").removeClass("active");
		};
	}
});
